import { collection, deleteDoc, doc, query as firebaseQuery, getDoc, getDocs, getFirestore, Timestamp, limit, orderBy, setDoc, startAfter, updateDoc, where } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import { ID } from '../../../../../../_metronic/helpers'
import JSZip from 'jszip';
import { convertSelectToArr, getDate, getDateTime, getDocsFromCollection, getDropdownOptionsFromFirebase, getAssociatedDocs, checkForBool, checkIfIndexRequired, getImageSize } from '../../../../../../jsFunctions'
import { generatePaginationLinks, getValueByNumber, getUniqueFileName, downloadFile } from '../../../../../../tsFunctions'
import { Content, ContentsQueryResponse, Session, SessionsQueryResponse } from './_models'

const collection_name = "_contents";
const API_URL = process.env.REACT_APP_THEME_API_URL
const CONTENT_URL = `${API_URL}/user`
const GET_CONTENTS_URL = `${API_URL}/users/query`

const getContents = (query: string): Promise<ContentsQueryResponse> => {
  const db = getFirestore()
  let params = new URLSearchParams(query);

  let sort = params.get('sort')
  let lastVisible = params.get('lastVisible')
  let page: number = Number(params.get('page'))
  let from: number = Number(params.get('from'))
  let search = params.get('search')?.toLowerCase()
  let order = params.get('order')
  let history = params.get('history')
  interface Filter {
    type: string; // replace with the actual types
    status: string
    // other properties...
  }
  let filter: Filter = Object({ type: params.get('filter_type'), status: params.get('filter_status') })

  const promises: Promise<void>[] = []; // Array to store promises

  const parseHistoryObj = (historyString: string) => {
    if (!historyString) return [];
    return JSON.parse(historyString);
  };

  const parsedHistory = parseHistoryObj(history as string);

  let startParam = getValueByNumber(page, parsedHistory, from, lastVisible);

  if (!lastVisible && startParam.value) {
    lastVisible = startParam.value
  }

  let q = firebaseQuery(collection(db, collection_name), limit(6));

  if (lastVisible && page > 1) {
    const counter = page - from;
    const pageLimit = 50;
    let entries = pageLimit * counter + 1;
    if (from > page) {
      let startParam = getValueByNumber(page, parsedHistory, from, lastVisible);
      if (startParam.value) {
        lastVisible = startParam.value ? startParam.value : lastVisible
      }
      if (page === startParam.key) {
        entries = pageLimit + 1;
      }
      else {
        entries = pageLimit * (page - startParam.key) + 1;
      }
    }

    return getDoc(doc(db, collection_name, lastVisible)).then((docSnap) => {
      if (sort && (order === "desc" || order === "asc") && search) {
        q = firebaseQuery(collection(db, collection_name), where("zearch", "array-contains", search), orderBy(sort, order), startAfter(docSnap), limit(entries))

        if (filter.status) {
          q = firebaseQuery(collection(db, collection_name), where("zearch", "array-contains", search), where(filter.type, "==", checkForBool(filter.status)), orderBy(sort, order), startAfter(docSnap), limit(entries))
        }
      }
      else if (sort && (order === "desc" || order === "asc")) {
        q = firebaseQuery(collection(db, collection_name), orderBy(sort, order), startAfter(docSnap), limit(entries))

        if (filter.status) {
          q = firebaseQuery(collection(db, collection_name), orderBy(sort, order), where(filter.type, "==", checkForBool(filter.status)), startAfter(docSnap), limit(entries))
        }
      }
      else if (search) {
        q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where("zearch", "array-contains", search), startAfter(docSnap), limit(entries))

        if (filter.status) {
          q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where(filter.type, "==", checkForBool(filter.status)), where("zearch", "array-contains", search), startAfter(docSnap), limit(entries))
        }
      }
      else {
        q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), limit(entries), startAfter(docSnap))

        if (filter.status) {
          q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where(filter.type, "==", checkForBool(filter.status)), limit(entries), startAfter(docSnap))
        }
      }

      return new Promise(resolve =>
        getDocs(q)
          .then(querySnapshot => {
            const contentsArr = Array()

            let docs = querySnapshot.docs.slice();
            let landingPage = Math.ceil((querySnapshot.size - 1) / pageLimit);
            if (querySnapshot.size === 1) {
              landingPage = 1;
            }
            if (page > from) {
              page = from + landingPage;
            }
            //remove entries for pages that are not required
            if (landingPage > 1) {
              const toSplice = ((landingPage - 1) * pageLimit);
              docs.splice(0, toSplice);
            }
            //remove one extra entry if page is not last page
            if (docs.length > pageLimit) {
              docs.splice(-1);
              lastVisible = docs[docs.length - 1].id;
            }
            else {
              lastVisible = null;
            }

            docs.forEach(document => {
              const user = document.data()

              contentsArr.push({
                id: document.id,
                dateCreated: getDateTime(user.dateCreated.seconds * 1000),
                updatedOn: user.updatedOn ? getDateTime(user.updatedOn.seconds * 1000) : getDateTime(user.dateCreated.seconds * 1000),
                qrCodes: user.qrCodes,
                prints: user.prints,
                mails: user.mails,
                sentMailsCount: user.sentMailsCount,
                template: user.template,
                templateType: user.templateType,
                consent: user.consent ? 'true' : 'false',
                isDooh: user.isDooh ? 'true' : 'false',
                sessionNumber: user.sessionNumber,
                session: user.session,
                photoroom: user.photoroom ? 'true' : 'false',
                emails: user.emails,
                parent: user.parent,
                info: user.info,
                projectNo: user.projectNo,
                name: user.name,
                password: user.password,
                first_name: user.first_name ? user.first_name : "Guest",
                last_name: user.last_name ? user.last_name : "Guest",
                fullname: user.fullname ? user.fullname : "Guest",
                occupation: user.occupation ? user.occupation : "N/A",
                companyName: user.companyName ? user.companyName : "N/A",
                phone: user.companyName ? user.companyName : "N/A",
                avatar: user.image ? user.image : null,
                video: user.video ? user.video : null,
                size: user.size,
                original: user.original ? user.original : user.image ? user.image : null,
                role: user.role,
                language: 'en',
                timeZone: user.timeZone ? user.timeZone : "N/A",
                website: user.website ? user.website : "N/A",
              })


              let parentPromise = getDropdownOptionsFromFirebase("uid", "_users", [user.creator], null, null, null, user.event).then(referenceTables => {
                const index_ = contentsArr.findIndex(obj => obj.id === document.id);

                console.log(referenceTables.associatedCollections)

                if (index_ !== -1) {
                  contentsArr[index_] = {
                    ...contentsArr[index_], parent: referenceTables.parent[0]
                    // , dooh: referenceTables.associatedCollections 
                  };
                }
              })

              promises.push(parentPromise);

              if (user.event) {

                let parentPromise2 = getDropdownOptionsFromFirebase("id", "_activations", [user.event === "admin" ? "Cm5DtcWUzLyuCnRtj8hX" : user.event], null, null, null, null).then(referenceTables => {
                  const index_ = contentsArr.findIndex(obj => obj.id === document.id);

                  if (index_ !== -1) {
                    console.log(contentsArr[index_].id, " ", contentsArr[index_].consent, " ", referenceTables.parent[0].data.isDooh ? contentsArr[index_].consent : "N/A")

                    contentsArr[index_] = {
                      ...contentsArr[index_],
                      // dooh: referenceTables.parent[0].data.isDooh,
                      dooh: checkForBool(referenceTables.parent[0].data.isDooh, true),
                      activations: referenceTables.parent
                      // consent: referenceTables.parent[0].data.isDooh ? contentsArr[index_].consent : "N/A" 
                    };
                  }
                })

                promises.push(parentPromise2);
              }


              let parentPromise3 = getSessionById(user.session).then((sessionDoc) => {

                const index_ = contentsArr.findIndex(obj => obj.id === document.id);

                console.log("checkForBool(sessionDoc?.isDooh, true) ", checkForBool(sessionDoc?.isDooh, true))


                contentsArr[index_] = { ...contentsArr[index_], isDooh: checkForBool(sessionDoc?.isDooh, true) }

              })

              promises.push(parentPromise3);


              let image = user.original ? user.original : user.image;

              if (image && !user.size) {
                // Adding another promise for user.original image size
                let imagePromise: Promise<void> = new Promise((resolve, reject) => {
                  let xhr = new XMLHttpRequest();
                  xhr.open('GET', image, true);
                  xhr.responseType = 'blob';
                  xhr.onload = function () {
                    if (xhr.status === 200) {
                      let blob = xhr.response;
                      let sizeInBytes = blob.size;
                      let sizeInMBs = sizeInBytes / (1024 * 1024); // Convert bytes to MBs
                      console.log("sizeInMBs ", sizeInMBs)
                      sizeInMBs = Math.round((sizeInMBs + Number.EPSILON) * 100) / 100

                      // Update Firestore with the calculated size
                      updateDoc(doc(db, collection_name, document.id), {
                        size: sizeInMBs
                      });

                      const index_ = contentsArr.findIndex(obj => obj.id === document.id);

                      if (index_ !== -1) {
                        contentsArr[index_] = { ...contentsArr[index_], size: sizeInMBs };
                      }

                      console.log("sizeInMBs ", sizeInMBs)

                      resolve(); // Resolving with void
                    } else {
                      console.log('Failed to fetch image ' + document.id + " " + image)
                      reject(new Error('Failed to fetch image ' + document.id + " " + image));
                    }
                  };
                  xhr.onerror = function () {
                    console.log('Failed to fetch image ' + document.id + " " + image)
                    reject(new Error('Failed to fetch image ' + document.id + " " + image));
                  };
                  xhr.send();
                });

                promises.push(imagePromise);
              }

            })



            return Promise.all(promises).then(() => {

              page = contentsArr.length ? page : 1

              resolve({
                data: contentsArr,
                payload: {
                  message: "N/A",
                  errors: {},
                  "pagination": {
                    page: page,
                    items_per_page: 50,
                    lastVisible: lastVisible,
                    "first_page_url": "/?page=1",
                    "from": 1,
                    "last_page": page,
                    "next_page_url": "\\/?page=" + (page + 1),
                    "prev_page_url": null,
                    "to": querySnapshot.docs.length + 1,
                    "history": { ...parsedHistory, [page]: contentsArr.length === pageLimit ? contentsArr[pageLimit - 1].id : null },
                    "links": generatePaginationLinks(page, lastVisible)
                  }
                }
              })
            })
          }).catch(error => {
            console.log(error)
            checkIfIndexRequired(error.message)
            resolve(error)
          }))

    })
  }
  else {
    const pageLimit = 50 * page;

    if (sort && (order === "desc" || order === "asc") && search) {
      q = firebaseQuery(collection(db, collection_name), where("zearch", "array-contains", search), orderBy(sort, order), limit(pageLimit + 1))

      if (filter.status) {
        q = firebaseQuery(collection(db, collection_name), where("zearch", "array-contains", search), where(filter.type, "==", checkForBool(filter.status)), orderBy(sort, order), limit(pageLimit + 1))
      }
    }
    else if (sort && (order === "desc" || order === "asc")) {
      q = firebaseQuery(collection(db, collection_name), orderBy(sort, order), limit(pageLimit + 1))

      if (filter.status) {
        q = firebaseQuery(collection(db, collection_name), orderBy(sort, order), where(filter.type, "==", checkForBool(filter.status)), limit(pageLimit + 1))
      }
    }
    else if (search) {
      q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where("zearch", "array-contains", search), limit(pageLimit + 1))

      if (filter.status) {
        q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where("zearch", "array-contains", search), where(filter.type, "==", checkForBool(filter.status)), limit(pageLimit + 1))
      }
    }
    else {
      q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), limit(pageLimit + 1))

      if (filter.status) {
        q = firebaseQuery(collection(db, collection_name), orderBy("dateCreated", "desc"), where(filter.type, "==", checkForBool(filter.status)), limit(pageLimit + 1))
      }
    }

    return new Promise(resolve =>
      getDocs(q)
        .then(querySnapshot => {
          const contentsArr = Array()
          let index = 0;



          querySnapshot.forEach(document => {



            if (pageLimit > 50 && index < pageLimit - 50) {

            }
            else {
              const user = document.data()

              contentsArr.push({
                id: document.id,
                dateCreated: getDateTime(user.dateCreated.seconds * 1000),
                updatedOn: user.updatedOn ? getDateTime(user.updatedOn.seconds * 1000) : getDateTime(user.dateCreated.seconds * 1000),
                // startDate: getDate(user.dateCreated.seconds * 1000),
                // endDate: getDate(user.dateCreated.seconds * 1000),
                qrCodes: user.qrCodes,
                prints: user.prints,
                mails: user.mails,
                sentMailsCount: user.sentMailsCount,
                template: user.template,
                templateType: user.templateType,
                consent: user.consent ? 'true' : 'false',
                isDooh: user.isDooh ? 'true' : 'false',
                sessionNumber: user.sessionNumber,
                session: user.session,
                photoroom: user.photoroom ? 'true' : 'false',
                emails: user.emails,
                parent: user.parent,
                info: user.info,
                projectNo: user.projectNo,
                name: user.name,
                password: user.password,
                email: user.email,
                first_name: user.first_name ? user.first_name : "Guest",
                last_name: user.last_name ? user.last_name : "Guest",
                fullname: user.fullname ? user.fullname : "Guest",
                occupation: user.occupation ? user.occupation : "N/A",
                companyName: user.companyName ? user.companyName : "N/A",
                phone: user.companyName ? user.companyName : "N/A",
                avatar: user.image ? user.image : null,
                video: user.video ? user.video : null,
                size: user.size,
                original: user.original ? user.original : user.image ? user.image : null,
                role: user.role,
                language: 'en',
                timeZone: user.timeZone ? user.timeZone : "N/A",
                website: user.website ? user.website : "N/A",
              })

              let parentPromise = getDropdownOptionsFromFirebase("uid", "_users", [user.creator], null, null, null, null).then(referenceTables => {
                const index_ = contentsArr.findIndex(obj => obj.id === document.id);

                // console.log("referenceTables.owner ", referenceTables.parent)

                if (index_ !== -1) {
                  contentsArr[index_] = { ...contentsArr[index_], parent: referenceTables.parent[0] };
                }
              })

              promises.push(parentPromise);

              let image = user.original ? user.original : user.image;

              if (image && !user.size) {
                // Adding another promise for user.original image size
                let imagePromise: Promise<void> = new Promise((resolve, reject) => {
                  let xhr = new XMLHttpRequest();
                  xhr.open('GET', image, true);
                  xhr.responseType = 'blob';
                  xhr.onload = function () {
                    if (xhr.status === 200) {
                      let blob = xhr.response;
                      let sizeInBytes = blob.size;
                      let sizeInMBs = sizeInBytes / (1024 * 1024); // Convert bytes to MBs
                      console.log("sizeInMBs ", sizeInMBs)
                      sizeInMBs = Math.round((sizeInMBs + Number.EPSILON) * 100) / 100

                      // Update Firestore with the calculated size
                      updateDoc(doc(db, collection_name, document.id), {
                        size: sizeInMBs
                      });

                      const index_ = contentsArr.findIndex(obj => obj.id === document.id);

                      if (index_ !== -1) {
                        contentsArr[index_] = { ...contentsArr[index_], size: sizeInMBs };
                      }

                      console.log("sizeInMBs ", sizeInMBs)

                      resolve(); // Resolving with void
                    } else {
                      console.log('Failed to fetch image ' + document.id + " " + image)
                      reject(new Error('Failed to fetch image ' + document.id + " " + image));
                    }
                  };
                  xhr.onerror = function () {
                    console.log('Failed to fetch image ' + document.id + " " + image)
                    reject(new Error('Failed to fetch image ' + document.id + " " + image));
                  };
                  xhr.send();
                });

                promises.push(imagePromise);
              }

              if (user.event) {
                let parentPromise2 = getDropdownOptionsFromFirebase("id", "_activations", [user.event === "admin" ? "Cm5DtcWUzLyuCnRtj8hX" : user.event], null, null, null, null).then(referenceTables => {
                  const index_ = contentsArr.findIndex(obj => obj.id === document.id);

                  console.log( "user ", user.id, " ", user.event,  " ", referenceTables)

                  if (index_ !== -1) {
                    contentsArr[index_] = {
                      ...contentsArr[index_],
                      dooh: checkForBool(referenceTables.parent[0].data.isDooh, true),
                      activations: referenceTables.parent
                      //  consent: referenceTables.parent[0].data.isDooh ? contentsArr[index_].consent : "N/A" 
                    };
                  }
                })

                promises.push(parentPromise2);
              }

              let parentPromise3 = getSessionById(user.session).then((sessionDoc) => {

                const index_ = contentsArr.findIndex(obj => obj.id === document.id);

                contentsArr[index_] = { ...contentsArr[index_], isDooh: checkForBool(sessionDoc?.isDooh, true) }

              })

              promises.push(parentPromise3);


            }
            index++
          })

          return Promise.all(promises).then(() => {
            page = contentsArr.length ? page : 1

            lastVisible = contentsArr.length > pageLimit ? contentsArr[contentsArr.length - 2].id : null

            resolve({
              data: contentsArr.length > pageLimit ? contentsArr.slice(0, contentsArr.length - 1) : contentsArr,
              payload: {
                message: "N/A",
                errors: {},
                "pagination": {
                  page: page,
                  items_per_page: 50,
                  lastVisible: lastVisible,
                  "first_page_url": "/?page=1",
                  "from": 1,
                  "next_page_url": "\\/?page=" + (page + 1),
                  "prev_page_url": "\\/?page=" + (page - 1),
                  "to": querySnapshot.docs.length + 1,
                  "history": { ...parsedHistory, [page]: contentsArr.length > pageLimit ? contentsArr[pageLimit - 1].id : null },
                  "links": generatePaginationLinks(page, lastVisible)
                }
              }
            })
          })
        }).catch(error => {
          console.log(error)
          checkIfIndexRequired(error.message)
          resolve(error)
        }))
  }
}

const getContentById = (id: ID): Promise<Content | undefined> => {
  const db = getFirestore()
  // let q = firebaseQuery(collection(db, collection_name))
  // q = firebaseQuery(collection(db, collection_name), where("image", "==", id))

  const docRef = doc(db, collection_name, id + "");

  const promises: Promise<void>[] = []; // Array to store promises

  return new Promise(resolve =>
    getDoc(docRef)
      .then(docSnap => {
        const usersArr = Array()

        if (docSnap.exists()) {
          const user = docSnap.data();

          // console.log(`${doc.id} => ${doc.data()}`)
          // const user = doc.data()
          usersArr.push({
            id: docSnap.id,
            dateCreated: getDateTime(user.dateCreated.seconds * 1000),
            updatedOn: user.updatedOn ? getDateTime(user.updatedOn.seconds * 1000) : getDateTime(user.dateCreated.seconds * 1000),
            consent: user.consent,
            isDooh: user.isDooh,
            qrCodes: user.qrCodes,
            sessionNumber: user.sessionNumber,
            templateType: user.templateType,
            session: user.session,
            mails: user.mails,
            sentMailsCount: user.sentMailsCount,
            emails: user.emails,
            prints: user.prints,
            tags: user.tags,
            parent: user.parent?.label,
            projectNo: user.projectNo,
            info: user.info,
            name: user.name,
            password: user.password,
            first_name: user.first_name ? user.first_name : "Guest",
            avatar: user.image ? user.image : null,
            video: user.video ? user.video : null,
            size: 0,
            original: user.original ? user.original : user.image ? user.image : null,
            activations: [],
            memoryMakers: [],
            templates: [],
            collections: []
          })

          let event = user.event;

          if (user.event == "admin") {
            event = 'Cm5DtcWUzLyuCnRtj8hX'
          }

          let parentPromise = getDropdownOptionsFromFirebase("id", "_activations", [event], null, [user.creator], [user.template], event).then(referenceTables => {
            const index_ = usersArr.findIndex(obj => obj.id === docSnap.id);

            console.log(referenceTables)

            if (index_ !== -1) {
              usersArr[index_] = { ...usersArr[index_], dooh: referenceTables.parent[0].data.isDooh, activations: referenceTables.parent, memoryMakers: referenceTables.memoryMakers, templates: referenceTables.templates, collections: referenceTables.associatedCollections };
            }
          })

          let parentPromise2 = getSessionById(user.session).then((sessionDoc) => {

            const index_ = usersArr.findIndex(obj => obj.id === docSnap.id);

            usersArr[index_] = { ...usersArr[index_], isDooh: checkForBool(sessionDoc?.isDooh) }
          })



          promises.push(parentPromise);
          promises.push(parentPromise2);
        }



        return Promise.all(promises).then(() => {
          resolve(usersArr[0])
        })

      }).catch(error => {
        console.error(error)
        resolve(error)
      }))
}



//hunnain - perfect code for syncing promises
const getSessionById = async (id: ID): Promise<Session | undefined> => {



  try {
    const db = getFirestore();
    const _id = id?.toString();

    // Reference to the specific document by ID
    const sessionRef = doc(collection(db, "_sessions"), _id);

    // Use getDoc to retrieve the document
    const docSnapshot = await getDoc(sessionRef);



    if (docSnapshot.exists()) {
      const user = docSnapshot.data();


      const sessionData: Session = {
        id: docSnapshot.id,
        dateCreated: getDate(user.createdAt),
        updatedOn: user.updatedOn?.seconds ? getDateTime(user.updatedOn.seconds * 1000) : getDate(user.createdAt),
        consent: user.consent,
        isDooh: user.isDooh,
        qrCodes: user.qrCodes,
        sessionNumber: user.sessionNumber,
        session: user.session,
        mails: user.mails,
        prints: user.prints,
        emails: user.emails,
        templates: [],
        memoryMakers: [],
        activations: [],
        collections: [],
        tags: user.tags,
        projectNo: user.projectNo,
        info: user.info,
        name: user.name,

        original: user.original ? user.original : user.image ? user.image : null,
      };

      console.log("sessionData.id ", sessionData.id)

      // Fetch additional data using async/await
      const contentsResponse = await getDocsFromCollection("session", "_contents", sessionData.id);

      console.log("contentsResponse ", contentsResponse)

      sessionData.parent = contentsResponse.docsArray.filter(item => !item.archive);
      console.log("contents ", contentsResponse.docsArray);

      // const memoryMakers = contentsResponse.docsArray.map((item, index) => item.data.creator)
      const memoryMakers = contentsResponse.docsArray
        .filter(item => !item.archive)
        .map(item => item.data.creator);

      const activationsResponse = await getDocsFromCollection("id", "_activations", contentsResponse.docsArray[0].data.event === "admin" ? "Cm5DtcWUzLyuCnRtj8hX" : contentsResponse.docsArray[0].data.event);
      sessionData.activations = activationsResponse.docsArray;
      // console.log("activations ", activationsResponse.docsArray);

      const collectionssResponse = await getAssociatedDocs(contentsResponse.docsArray[0].data.event === "admin" ? "Cm5DtcWUzLyuCnRtj8hX" : contentsResponse.docsArray[0].data.event);

      // console.log("collectionssResponse ", collectionssResponse)
      sessionData.collections = collectionssResponse;
      // console.log("activations ", collectionssResponse.docsArray);

      // console.log("memoryMakers[0] ", memoryMakers[0])

      const memoryMakersResponse = await getDocsFromCollection("uid", "_users", memoryMakers[0]);
      sessionData.memoryMakers = memoryMakersResponse.docsArray;
      // console.log("memory makers ", memoryMakersResponse.docsArray);

      // let activationsResponse = getDropdownOptionsFromFirebase("id", "_activations", contentsResponse.docsArray[0].data.event === "admin" ? ["Cm5DtcWUzLyuCnRtj8hX"] : [contentsResponse.docsArray[0].data.event], null, creators, null).then(referenceTables => {

      //   console.log(referenceTables)
      //   sessionData.activations = referenceTables.parent
      // })



      if (activationsResponse.docsArray) {
        sessionData.dooh = activationsResponse.docsArray[0].data.isDooh;
        sessionData.microsite = activationsResponse.docsArray[0].data.isMicrosite;
      }

      return sessionData;
    } else {
      // Document does not exist
      return undefined;
    }
  } catch (error) {
    console.error(error);
    throw error; // Propagate the error further if needed
  }
};

const getSessionById1 = (id: ID): Promise<Session | undefined> => {
  const db = getFirestore();

  let _id = id?.toString()
  // Reference to the specific document by ID
  const sessionRef = doc(collection(db, "_sessions"), _id);

  const promises: Promise<void>[] = []; // Array to store promises


  // Use getDoc to retrieve the document
  return new Promise((resolve) =>
    getDoc(sessionRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const user = docSnapshot.data();
          const sessionData: Session = {
            id: docSnapshot.id,
            dateCreated: getDate(user.createdAt),
            updatedOn: user.updatedOn ? getDateTime(user.updatedOn.seconds * 1000) : getDateTime(user.dateCreated.seconds * 1000),
            consent: user.consent,
            qrCodes: user.qrCodes,
            sessionNumber: user.sessionNumber,
            session: user.session,
            mails: user.mails,
            prints: user.prints,

            templates: [],
            memoryMakers: [],
            activations: [],
            collections: [],
            tags: user.tags,
            projectNo: user.projectNo,
            info: user.info,
            name: user.name,
            original: user.original ? user.original : user.image ? user.image : null,
          };

          // You can also add any additional processing if needed

          let parentPromise = getDocsFromCollection("session", "_contents", sessionData.id)
            .then(response => {
              sessionData.parent = response.docsArray;

              console.log("contents ", response.docsArray);

              return getDocsFromCollection("id", "_activations", response.docsArray[0].data.event === "admin" ? "Cm5DtcWUzLyuCnRtj8hX" : response.docsArray[0].data.event);
            })
            .then(response => {
              sessionData.activations = response.docsArray;

              console.log("activations ", response.docsArray);

              if (response.docsArray) {
                sessionData.dooh = response.docsArray[0].data.isDooh;
              }

              return sessionData;
            })
            .catch(error => {
              console.error(error);
              throw error; // Propagate the error further if needed
            });

          return parentPromise;


          // return Promise.all(promises)
          //   .then(() => {
          //     console.log("sessionData ", sessionData);
          //     resolve(sessionData);
          //   })
          //   .catch(error => {
          //     console.error("Error:", error);
          //     // Handle the error appropriately
          //   });
        } else {
          // Document does not exist
          resolve(undefined);
        }
      })
      .catch((error) => {
        console.error(error);
        resolve(error);
      })
  );
};

const uploadAndUpdate = (id: string, avatar: any, name: string) => {
  const db = getFirestore()
  const storage = getStorage()
  const storageRef = ref(storage, '/panelContents/dp/' + name)
  // 'file' comes from the Blob or File API
  uploadBytes(storageRef, avatar).then((snapshot) => {
    getDownloadURL(snapshot.ref).then((downloadURL) => {
      updateDoc(doc(db, collection_name, id), {
        avatar: downloadURL
      });

      // updateUser({ id: panelUser.id, avatar: downloadURL })
    })
  })
}

// const updateContent = (content: Content): Promise<Content | undefined> => {
//   const db = getFirestore()
//   if (content.id) {
//     const _contents = doc(db, collection_name, content.id)
//     return new Promise(resolve => setDoc(_contents, { consent: content.consent, isDooh: content.isDooh, updatedOn: Timestamp.now() }, { merge: true })
//       .then((response) => {
//         console.log(response)
//         if (content.session) {
//           const _sessions = doc(db, "_sessions", content.session)

//           new Promise(resolve => setDoc(_sessions, { consent: content.consent, isDooh: content.isDooh, updatedOn: Timestamp.now() }, { merge: true })
//             .then((response) => {
//               console.log(response)
//               resolve(content)
//             }).catch(error => {
//               console.log(error)
//               resolve(undefined)
//             }))
//         }

//         resolve(content)
//       }).catch(error => {
//         console.log(error)
//         resolve(undefined)
//       }))
//   }
//   else {
//     return new Promise(resolve =>
//       resolve(undefined)
//     )
//   }
// }

const updateContent = (content: Content): Promise<Content | undefined> => {
  const db = getFirestore();
  if (content.id) {
    const _contents = doc(db, collection_name, content.id);
    return new Promise(resolve =>
      setDoc(_contents, { consent: content.consent, isDooh: content.isDooh, updatedOn: Timestamp.now() }, { merge: true })
        .then((response) => {
          console.log(response);

          if (content.session) {
            const sessionData = {
              id: content.session,
              consent: content.consent,
              isLocked: content.isLocked || false,
              isDooh: content.isDooh
            };

            if (content.isLocked) {
              updateSession(sessionData).then(() => {
                resolve(content);
              }).catch(error => {
                console.log(error);
                resolve(undefined);
              });
            } else {
              resolve(content);
            }
          } else {
            resolve(content);
          }
        }).catch(error => {
          console.log(error);
          resolve(undefined);
        })
    );
  } else {
    return new Promise(resolve => resolve(undefined));
  }
}

const updateSession = (session: Session): Promise<Content | undefined> => {
  const db = getFirestore()
  if (session.id) {
    const _sessions = doc(db, "_sessions", session.id);

    return new Promise(resolve =>
      setDoc(_sessions, session.isLocked ? { consent: session.consent, isLocked: true, isDooh: session.isDooh } : { consent: session.consent }, { merge: true })
        .then((response) => {
          console.log(response);
          resolve(session);
        }).catch(error => {
          console.log(error);
          resolve(undefined);
        })
    );
  } else {
    return new Promise(resolve => resolve(undefined));
  }
}

const createContent = (content: Content): Promise<Content | undefined> => {
  const db = getFirestore()
  if (content.id) {
    const _contents = doc(db, collection_name, content.id)
    return new Promise(resolve => setDoc(_contents, { avatar: content.avatar, name: content.name, tags: convertSelectToArr(content.tags), info: content.info }, { merge: true })
      .then((response) => {
        console.log(response)
        resolve(content)
      }).catch(error => {
        console.log(error)
        resolve(undefined)
      }))
  }
  else {
    return new Promise(resolve =>
      resolve(undefined)
    )
  }
}

const archiveContent = (contentId: ID): Promise<void> => {
  const db = getFirestore();
  const collectionName = "archive" + collection_name;

  if (contentId) {
    const userDocRef = doc(db, collection_name, contentId);

    // Retrieve the document data
    return getDoc(userDocRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.data();

          // Set the document data in the new collection
          const archiveDocRef = doc(db, collectionName, contentId);
          return setDoc(archiveDocRef, userData)
            .then(() => {
              // Delete the document from the original collection
              return deleteDoc(userDocRef);
            })
            .catch((error) => {
              console.error("Error archiving user:", error);
              throw error;
            });
        } else {
          throw new Error("User document not found.");
        }
      })
      .catch((error) => {
        console.error("Error retrieving user document:", error);
        throw error;
      });
  } else {
    return Promise.reject(new Error("Invalid user ID"));
  }
}

const archiveSelectedContents = (selectedIds: Array<ID>): Promise<void> => {
  const db = getFirestore();
  const collectionName = "archive" + collection_name;

  // Use Promise.all to run the promises concurrently
  const archivePromises = selectedIds.map(documentId => {
    if (documentId) {
      const userDocRef = doc(db, collection_name, documentId);

      // Retrieve the document data
      return getDoc(userDocRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.data();

            // Set the document data in the new collection
            const archiveDocRef = doc(db, collectionName, documentId);
            return setDoc(archiveDocRef, userData)
              .then(() => {
                // Delete the document from the original collection
                return deleteDoc(userDocRef);
              })
              .catch((error) => {
                console.error("Error archiving template:", error);
                throw error;
              });
          } else {
            throw new Error(`document with ID ${documentId} not found.`);
          }
        })
        .catch((error) => {
          console.error(`Error retrieving document with ID ${documentId}:`, error);
          throw error;
        });
    } else {
      return Promise.reject(new Error("Invalid ID"));
    }
  });

  // Return a promise that resolves when all archive operations are complete
  return Promise.all(archivePromises).then(() => { });
};

// above is my download image function

// I need to download multiple images in zip format now. below is my function that will collect multiple images urls
const exportSelectedContents = (selectedIds: Array<ID>): Promise<void> => {
  const db = getFirestore();
  const zip = new JSZip();

  // Use Promise.all to run the promises concurrently
  const downloadPromises = selectedIds.map(documentId => {
    if (documentId) {
      const userDocRef = doc(db, collection_name, documentId);

      // Retrieve the document data
      return getDoc(userDocRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.data();
            const imageUrl = userData?.original ? userData.original : userData.image; // Assuming the image parameter is named "image"

            if (imageUrl) {
              // Download image and add it to the zip file
              return downloadFile(imageUrl)
                .then((blob: any) => {
                  const baseFileName = `${userData.sessionNumber.toLowerCase()}-${userData.templateType}.png`;
                  const fileName = getUniqueFileName(zip, baseFileName);
                  zip.file(fileName, blob);
                })
                .catch((error) => {
                  console.error(`Error downloading image for document with ID ${documentId}:`, error);
                  throw error;
                });
            } else if (userData.video) {
              return downloadFile(userData.video)
                .then((blob: any) => {
                  const baseFileName = `${userData.sessionNumber.toLowerCase()}-${userData.templateType}.mp4`;
                  const fileName = getUniqueFileName(zip, baseFileName);
                  zip.file(fileName, blob);
                })
                .catch((error) => {
                  console.error(`Error downloading image for document with ID ${documentId}:`, error);
                  throw error;
                });
            }
            else {
              console.warn(`Image not found in document with ID ${documentId}`);
              return null;
            }
          } else {
            throw new Error(`Document with ID ${documentId} not found.`);
          }
        })
        .catch((error) => {
          console.error(`Error retrieving document with ID ${documentId}:`, error);
          throw error;
        });
    } else {
      return Promise.reject(new Error("Invalid ID"));
    }
  });

  // Return a promise that resolves when all download operations are complete
  return Promise.all(downloadPromises)
    .then(() => {
      // Generate and download the zip file
      zip.generateAsync({ type: 'blob' })
        .then((content) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(content);
          link.download = 'saycheese_content_exports.zip';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error('Error generating zip file:', error);
          throw error;
        });
    });
};

interface dropdownOptions {
  readonly value: string;
  readonly avatar?: string;
  readonly label: string;
  readonly color: string;
  readonly id: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
  readonly tags?: Array<string>
}

const getMemoryMakers = (uid: boolean): Promise<dropdownOptions[]> => {
  const db = getFirestore()
  let q = firebaseQuery(collection(db, "_users"), where("role", "==", "Memory Maker"))

  return new Promise(resolve =>
    getDocs(q)
      .then(querySnapshot => {

        const memoryMakersArr: dropdownOptions[] = [];

        querySnapshot.forEach((doc) => {
          const memoryMaker = doc.data()
          if (memoryMaker.uid) {
            memoryMakersArr.push({
              value: uid ? memoryMaker.uid : memoryMaker.id,
              id: uid ? memoryMaker.uid : memoryMaker.id,
              label: memoryMaker.name,
              color: '#000',
              isFixed: true,
              isDisabled: false,
            })
          }
        })

        resolve(memoryMakersArr)

      }).catch(error => {
        console.error(error)
        // resolve(dropdownOptions)
      }))
}

const getActivations = (): Promise<dropdownOptions[]> => {
  const db = getFirestore()
  let q = firebaseQuery(collection(db, "_activations"))

  return new Promise(resolve =>
    getDocs(q)
      .then(querySnapshot => {

        const activationsArr: dropdownOptions[] = [];

        querySnapshot.forEach((doc) => {
          const activation = doc.data()
          if (activation.id) {
            activationsArr.push({
              value: activation.id,
              id: activation.id,
              label: activation.name + " (" + getDate(activation.startDate.seconds * 1000) + ")",//activation.name.length?activation.name:activation.id,
              color: '#000',
              isFixed: true,
              isDisabled: false,
            })
          }
        })

        resolve(activationsArr)

      }).catch(error => {
        console.error(error)
      }))
}

export { getContents, archiveContent, archiveSelectedContents, getContentById, createContent, updateContent, uploadAndUpdate, updateSession, getSessionById, getMemoryMakers, getActivations, exportSelectedContents }


import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { initialQueryState } from '../../../../../../../_metronic/helpers'
import { DropdownFilter } from '../../../../../../../components'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { getClients } from '../../core/_requests'

interface dropdownOptions {
  readonly value: string | boolean;
  readonly avatar?: string;
  readonly label: string;
  readonly color: string;
  readonly id: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
  readonly tags?: Array<string>
}

const templateTypes: dropdownOptions[] = [
  {
    value: 'ai',
    label: 'AI',
    color: '#00B8D9',
    id: 'true',
    isFixed: true,
    isDisabled: false,
    tags: ['tag1', 'tag2'],
  },
  {
    value: 'magic',
    label: 'Magic',
    color: '#00B8D9',
    id: 'true',
    isFixed: true,
    isDisabled: false,
    tags: ['tag1', 'tag2'],
  },
  {
    value: 'classic',
    label: 'Classic',
    color: '#00B8D9',
    id: 'false',
    isFixed: true,
    isDisabled: false,
    tags: ['tag3', 'tag4'],
  },
  {
    value: 'sticker',
    label: 'Sticker',
    color: '#00B8D9',
    id: 'false',
    isFixed: true,
    isDisabled: false,
    tags: ['tag3', 'tag4'],
  },
];

const EventsListFilter = () => {
  const { updateState, state } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [type, setType] = useState<string | undefined>('')
  const [status, setStatus] = useState<string | undefined>('')
  const [isOpen, setIsOpen] = useState<string | null>(null);
  const [isBackdropVisible, setIsBackdropVisible] = useState(false);

  const type_options = [{ value: 'owner', label: 'Clients (Owner)' }, { value: 'clients', label: 'Shared Clients' }]
  const [status_options, setStatusOptions] = useState<dropdownOptions[] | undefined>(undefined);

  useEffect(() => {
    switch (type) {
      default:
        //no case required, status option is equivalent to clients
        setStatusOptions(clients);
    }
  }, [type])

  const resetData = () => {
    setIsOpen(null)
    setType(undefined);
    setStatus(undefined);
    setIsBackdropVisible(false);
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    console.log("filterData()")
    console.log(status, status_options?.[0].value)
    if (status && status_options?.filter((item) => item.value === status).length) {
      console.log(type)
      updateState({
        ...initialQueryState,
        filter: { type, status },
        view: state.view
      })
    }
    setIsOpen(null)
    setIsBackdropVisible(false);
    return
  }

  const {
    data: clients,
  } = useQuery(
    ['clients'], // Use a descriptive key
    () => {
      return getClients();
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  return (
    <>
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown position-absolute w-300px w-md-325px' data-kt-menu='true' style={{ top: '10px', zIndex: '1000' }}>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Event */}
        <div className='px-7 py-5' data-kt-event-table-filter='form'>
          <DropdownFilter
            label="Type"
            options={type_options}
            value={type}
            setValue={setType}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isBackdropVisible={isBackdropVisible}
          />

          <div className='mb-10' style={{ opacity: isBackdropVisible ? 0.25 : 1 }}>
            <DropdownFilter
              label="Status"
              options={status_options || []}
              value={status}
              setValue={setStatus}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isBackdropVisible={isBackdropVisible}
            />
          </div>


          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}

              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-event-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}

              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-event-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Event */}
      </div >
      {/* end::SubMenu */}
    </>
  )
}

export { EventsListFilter }

import {ID, Response} from '../../../../../../_metronic/helpers'
export type Template = {
 
  id?: string
  name?: string
  avatar?: any
  original?: any
  raw?: any
  video?: any
  type?: 'classic' | 'magic' | 'sticker' | 'ai'
  dateCreated?: string
  updatedOn?: string
  disable?: boolean,
  dooh?: boolean
  uploadAllowed?: boolean
  microsite?: boolean
  print?: boolean
  dimensions?: {width?: "", height?:""} //{height: 1080, width: 720 }
  template?: any
  tags?: Array<string>
  info?: string

}

export type TemplatesQueryResponse = Response<Array<Template>>

export const initialTemplate: Template = {
  avatar: '',
  name: '',
  info: '',
  type: 'classic',
  dooh: false,
  microsite: false,
  disable: false,
  print: false,
  uploadAllowed: false,
}

import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { initialQueryState } from '../../../../../../../_metronic/helpers'
import { DropdownFilter } from '../../../../../../../components'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { getActivations, getMemoryMakers } from '../../core/_requests'

interface dropdownOptions {
  readonly value: string | boolean;
  readonly avatar?: string;
  readonly label: string;
  readonly color: string;
  readonly id: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
  readonly tags?: Array<string>
}

// const booleans = [{ value: true, label: 'Enabled' }, { value: false, label: "Disabled" }];

const booleans: dropdownOptions[] = [
  {
    value: 'true',
    label: 'Yes',
    color: '#00B8D9',
    id: 'true',
    isFixed: true,
    isDisabled: false,
    tags: ['tag1', 'tag2'],
  },
  {
    value: 'false',
    label: 'No',
    color: '#00B8D9',
    id: 'false',
    isFixed: true,
    isDisabled: false,
    tags: ['tag3', 'tag4'],
  },
];

const templateTypes: dropdownOptions[] = [
  {
    value: 'ai',
    label: 'AI',
    color: '#00B8D9',
    id: 'true',
    isFixed: true,
    isDisabled: false,
    tags: ['tag1', 'tag2'],
  },
  {
    value: 'magic',
    label: 'Magic',
    color: '#00B8D9',
    id: 'true',
    isFixed: true,
    isDisabled: false,
    tags: ['tag1', 'tag2'],
  },
  {
    value: 'classic',
    label: 'Classic',
    color: '#00B8D9',
    id: 'false',
    isFixed: true,
    isDisabled: false,
    tags: ['tag3', 'tag4'],
  },
  {
    value: 'sticker',
    label: 'Sticker',
    color: '#00B8D9',
    id: 'false',
    isFixed: true,
    isDisabled: false,
    tags: ['tag3', 'tag4'],
  },
];

// const templateTypes = [{ value: true, label: 'Enabled' }, { value: false, label: "Disabled" }];

const ContentsListFilter = () => {
  const { updateState, state } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [type, setType] = useState<string | undefined>('')
  const [status, setStatus] = useState<string | undefined>('')
  const [isOpen, setIsOpen] = useState<string | null>(null);
  const type_options = [
    { value: 'creator', label: 'Memory Makers' }, 
    { value: 'event', label: 'Activations' }, 
    { value: 'templateType', label: 'Template Type' }, 
    { value: 'consent', label: 'Consent' }
  ]
  const [status_options, setStatusOptions] = useState<dropdownOptions[] | undefined>(undefined);

  useEffect(() => {
    switch (type) {
      case "creator":
        setStatusOptions(memoryMakers);
        break;
      case "event":
        setStatusOptions(activations);
        break;
      case "templateType":
        setStatusOptions(templateTypes);
        break;
      case "consent":
        setStatusOptions(booleans);
        break;
      default:
        setStatusOptions(undefined);
    }
  }, [type])

  const [isBackdropVisible, setIsBackdropVisible] = useState(false);

  const {
    data: memoryMakers,
  } = useQuery(
    ['memoryMakers'], // Use a descriptive key
    () => {
      return getMemoryMakers(true);
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const {
    data: activations,
  } = useQuery(
    ['event'], // Use a descriptive key
    () => {
      return getActivations();
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err) => {
        console.error(err);
      },
    }
  );
  
  const resetData = () => {
    setIsOpen(null)
    setType(undefined);
    setStatus(undefined);
    setIsBackdropVisible(false);
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    if (status && status_options?.filter((item) => item.value === status).length) {
      updateState({
        ...initialQueryState,
        filter: { type, status },
        view: state.view
      })
    }
    setIsOpen(null)
    setIsBackdropVisible(false);
    return
  }

  return (
    <>
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown position-absolute w-300px w-md-325px' data-kt-menu='true' style={{ top: '10px', zIndex: '1000' }}>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-event-table-filter='form'>

          {/* begin::Input group */}
          {/* <label className='form-label fs-6 fw-bold mt-5'>Type:</label>
          <Select
            placeholder="Select Field to Filter"
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            onChange={(newValue) => {



              setType(newValue?.value)
              setTimeout(() => setIsOpen(false), 100)
              newValue?.label === "Memory Makers" ?
              setStatusOptions(memoryMakers)
                :
                newValue?.label === "Activations" ?
                  setStatusOptions(activations)
                  :
                  newValue?.label === "Template Type" ?
                    setStatusOptions(templateTypes)
                    :
                    newValue?.label === "Consent" ?
                      setStatusOptions(booleans)
                      :
                      setStatusOptions(undefined)
            }}
            menuIsOpen={isOpen}
            onMenuOpen={() => {
              setIsOpen(true);
              setIsOpen2(false)
            }}
            backspaceRemovesValue={false}
            isOptionSelected={(opt, allOpts) => opt.value === type}
            blurInputOnSelect={false}
            className='text-uppercase fs-8'
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
              },
            })}
            isMulti={false}
            styles={{
              option: (base, state) => ({
                ...base,
              }),
              multiValue: (styles, { data }) => {
                return {
                  ...styles,
                  borderRadius: 10,
                  backgroundColor: '#432cffb8',
                  color: 'white',
                  boxShadow: '#0303030f 0px 0px 20px 0px'
                };
              },
              multiValueLabel: (styles, { data }) => ({
                ...styles,
                fontWeight: 'bold',
                color: 'white'

              }),
              multiValueRemove: (styles, { data }) => ({
                ...styles,
                ':hover': {
                  color: 'gray',
                },
              }),
              control: (base) => ({
                ...base,
                padding: "5px",
                border: "none",
                borderRadius: 10,
                boxShadow: '#3d88a614 0px 0px 20px 0px'
              }),
              menu: (base) => ({
                ...base,
                // zIndex: 19999,
                backgroundColor: "white",
                padding: "10px",
                border: "none",
                borderRadius: 10,
                boxShadow: '#3d88a614 0px 0px 20px 0px'
              }),
              singleValue: (base) => ({ ...base, color: "#000", borderRadius: 10, }),
            }}
            isClearable={true}
            isRtl={false}
            isSearchable={true}
            name="color"
            options={type_options}
            value={type ? type_options.filter((item) => item.value === type) : null}
          /> */}
          {/* end::Input group */}


          {/* begin::Input group */}
          {/* <div className='mb-10' style={{ opacity: isBackdropVisible ? 0.25 : 1 }}>
            <label className='form-label fs-6 fw-bold mt-5'>Status:</label>
            <Select
              placeholder={`Select value for filter `}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              onChange={(newValue) => {

                console.log("newValue ", newValue)

                setStatus(newValue?.value)
                setTimeout(() => setIsOpen2(false), 100)
              }}
              menuIsOpen={isOpen2}
              onMenuOpen={() => {
                setIsOpen(false)
                setIsOpen2(true);
              }}
              backspaceRemovesValue={false}
              isOptionSelected={(opt, allOpts) => opt.value === type}
              blurInputOnSelect={false}
              className='text-uppercase fs-8'
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                },
              })}
              isMulti={false}
              styles={{
                option: (base, state) => ({
                  ...base,
                }),
                multiValue: (styles, { data }) => {
                  return {
                    ...styles,
                    borderRadius: 10,
                    backgroundColor: '#432cffb8',
                    color: 'white',
                    boxShadow: '#0303030f 0px 0px 20px 0px'
                  };
                },
                multiValueLabel: (styles, { data }) => ({
                  ...styles,
                  fontWeight: 'bold',
                  color: 'white'

                }),
                multiValueRemove: (styles, { data }) => ({
                  ...styles,
                  ':hover': {
                    color: 'gray',
                  },
                }),
                control: (base) => ({
                  ...base,
                  padding: "5px",
                  border: "none",
                  borderRadius: 10,
                  boxShadow: '#3d88a614 0px 0px 20px 0px'
                }),
                menu: (base) => ({
                  ...base,
                  // zIndex: 19999,
                  backgroundColor: "white",
                  padding: "10px",
                  border: "none",
                  borderRadius: 10,
                  boxShadow: '#3d88a614 0px 0px 20px 0px'
                }),
                singleValue: (base) => ({ ...base, color: "#000", borderRadius: 10, }),
              }}
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name="color"
              options={
                status_options
              }
              value={status ? status_options?.filter((item) => item.value === status) : null}
            />

          </div> */}
          {/* end::Input group */}
          
            <DropdownFilter
              label="Type"
              // options={[
              //   { value: 'creator', label: 'Memory Makers' },
              //   { value: 'event', label: 'Activations' },
              //   { value: 'templates', label: 'Template Type' },
              //   { value: 'consent', label: 'Consent' },
              // ]}
              options={type_options}
              value={type}
              setValue={setType}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isBackdropVisible={isBackdropVisible}
            />

            <div className='mb-10' style={{ opacity: isBackdropVisible ? 0.25 : 1 }}>
              <DropdownFilter
                label="Status"
                options={status_options || []}
                value={status}
                setValue={setStatus}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                isBackdropVisible={isBackdropVisible}
              />
            </div>
          
          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}

              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-event-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}

              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-event-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div >
      {/* end::SubMenu */}
    </>
  )
}

export { ContentsListFilter }
